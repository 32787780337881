export const fields = [
    { key: 'index', label: "No", _style:'min-width:50px'},
    { key: 'select', label: "", _style:'min-width:50px' },
    // { key: 'progress', label: "", _style:'min-width:50px' },
    { key: 'nama_paket', label: "Nama Desa", _style:'min-width:100px' },
    { key: "jenis_pekerjaan", label: "Sumber Air", _style:'min-width:50px'},
    { key: 'status_pekerjaan', label: "Debit", _style:'min-width:100px;' },
    { key: 'alamat_pekerjaan', label: "Alamat", _style:'min-width:100px;' },
    { key: 'tahun_anggaran', label: "Tahun", _style:'min-width:100px;' },
    { key: 'sumber_dana',label: "Sumber Anggaran", _style:'min-width:100px;' },
    { key: 'kecamatan', label: "Kecamatan", _style:'min-width:100px;' },
    { key: "volume", label: "Jumlah SR", _style:'min-width:50px'},
    { key: "jenis_pemilihan", label: 'Status', _style: 'min-width:100px'},
    { key: "pemenang_tender", label: 'Keterangan', _style: 'min-width:100px'},
    { key: 'nilai_kontrak', label: "Sambungan Rumah", _style:'min-width:100px;' },
    { key: 'longitude', label: "Longitude", _style:'min-width:100px;' },
    { key: 'latitude', label: "Latitude", _style:'min-width:100px;' },

    // { key: "satuan", label: "Satuan (Meter/Unit)", _style:'min-width:50px'},
    // { key: "jenis_lahan", label: 'Jenis Lahan', _style: 'min-width:100px'},
    // { key: 'full_name', label: "User", _style:'min-width:100px' },
    // { key: 'opd', label: "BPP", _style:'min-width:100px' },
    { key: "action", label: 'Aksi', _style: 'min-width:75px'}
]
